import { DashboardAction, DashboardGetter, DashboardMutation } from "../types"
import { formatDatetime, formatNumber, formatPercent, formatPrice } from '../../utils'
import { GetDashboard } from "../../graphql/dashboard"
import http from "../../http"

const Status = {
  Loading: "loading",
  Failed: "failed",
  Succeed: "succeed",
}

function formatPeriodStats(periodStats) {
  return {
    dateRange: {
      from: formatDatetime(periodStats.dateRange?.from),
      to: formatDatetime(periodStats.dateRange?.to),
    },
    compareWith: {
      from: formatDatetime(periodStats.compareWith?.from),
      to: formatDatetime(periodStats.compareWith?.to),
    },
    stats: periodStats.stats?.map(
      stat => ({
        ...stat,
        value: ['gmv', 'refundedAmount'].includes(stat.type)
          ? formatPrice({ amount: stat.value, currency: 'EUR' })
          : formatNumber(stat.value),
        evolution: {
          value:  stat.evolution !== null ? formatPercent(stat.evolution / 100, { alwaysShowSign: true }) : '-',
          progression: !stat.evolution ? '' : stat.evolution > 0 ? '+' : '-',
        },
      }
      )),
  }
}

export default {
  state: {
    daily: [],
    monthly: [],
    status: null,
  },
  mutations: {
    [DashboardMutation.GetAll]: (state, { daily, monthly }) => {
      state.daily = daily
      state.monthly = monthly
      state.status = Status.Succeed
    },
    [DashboardMutation.Failed]: (state) => state.status = Status.Failed,
    [DashboardMutation.Loading]: (state) => state.status = Status.Loading,
  },
  actions: {
    [DashboardAction.GetAll]: async ({ commit }) => {
      commit(DashboardMutation.Loading)

      http.post('/graphql', JSON.stringify({
        query: GetDashboard,
      }))
        .then((res) => {

          commit(DashboardMutation.GetAll, res.data.data)
        })
        .catch(() => commit(DashboardMutation.Failed))
    },
  },
  getters: {
    [DashboardGetter.GetDaily]: (state) => {
      return formatPeriodStats(state.daily)
    },
    [DashboardGetter.GetMonthly]: (state) => {
      return formatPeriodStats(state.monthly)
    },
    [DashboardGetter.IsLoading]: (state) => state.status === Status.Loading,
    [DashboardGetter.HasError]: (state) => state.status === Status.Failed,
  },
}
