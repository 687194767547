<template>
  <v-app>
    <NavBar />
    <v-main class="bo_app">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from './components/Layout/NavBar'
import { RootAction } from './store/types'

export default {
  components: {
    NavBar,
  },
  beforeCreate() {
    this.$store.dispatch(RootAction.Init)
  },
}

</script>

<style scoped>
.bo_app  {
  background-color: #FAFAFA;
}
</style>
