<template>
  <Page
    :loading="loading"
    :error="error"
  >
    <template
      v-if="customer"
      #title
    >
      <v-card
        outlined
        elevation="2"
      >
        <v-card-title>
          <h2>Détails du client #{{ customer.shortId }}</h2>
          <v-spacer />
          <v-icon
            v-if="customer.deactivated"
            color="red darken-4"
            class="mr-2"
          >
            mdi-account-lock-outline
          </v-icon>
          <v-icon
            v-if="customer.anonymizedAt !== null"
            color="red darken-4"
            class="mr-2"
          >
            mdi-incognito
          </v-icon>
          <v-menu
            bottom
            left
          >
            <template #activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-if="!customer.deactivated"
                @click="openDeactivationModal"
              >
                <v-list-item-icon>
                  <v-icon>mdi-account-lock-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Désactiver
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                v-if="customer.anonymizedAt === null"
                @click="openAnonymizationModal"
              >
                <v-list-item-icon>
                  <v-icon>mdi-incognito</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  Anonymiser
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-title>
      </v-card>
    </template>
    <template><DeactivationModal /></template>
    <template><AnonymizationModal /></template>
    <template v-if="customer">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            height="100%"
            data-testid="personal-data"
          >
            <v-card-title>Données personnelles</v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="font-weight-medium">
                  Nom
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.lastName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Prénom
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.firstName }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Email
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.emailAddress }}
                  <v-btn
                    :href="'https://fly.customer.io/workspaces/122885/journeys/people?email='
                      + customer.emailAddress.trim()"
                    color="blue lighten-1"
                    small
                    target="_blank"
                    text
                  >
                    Voir sur Customer.io
                    <v-icon
                      color="blue lighten-1"
                      right
                      small
                    >
                      mdi-open-in-new
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Pays
                </v-col>
                <v-col class="font-weight-light">
                  {{ getCountryFlag }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Pseudo
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.pseudo }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Date de naissance
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.birthDate }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-card
            height="100%"
            data-testid="info-account"
          >
            <v-card-title>
              Compte
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="font-weight-medium">
                  Locale
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.locale }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Date de création
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.createdAt }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Dernière mise-à-jour
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.updatedAt }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Dernière connection
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.connectionAt }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Dernière activité
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.activeAt }}
                </v-col>
              </v-row>
              <v-row v-if="customer.anonymizedAt !== null">
                <v-col class="font-weight-medium">
                  Anonymisé le :
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.anonymizedAt }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Date d'optin
                </v-col>
                <v-col class="font-weight-light">
                  {{ customer.newsletterSubscriptionIntendedAt }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          data-testid="info-tab"
          cols="12"
          md="12"
        >
          <v-card>
            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab key="orders">
                  Commandes
                </v-tab>
                <v-tab key="postalAddressEntries">
                  Adresses
                </v-tab>
                <v-tab key="sizes">
                  Tailles
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                class="my-4"
              >
                <v-tab-item key="orders">
                  <v-simple-table>
                    <template #default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Réference
                          </th>
                          <th class="text-center">
                            Date
                          </th>
                          <th class="text-center">
                            État
                          </th>
                          <th class="text-center">
                            Coupon/Cartes Cadeaux
                          </th>
                          <th class="text-right">
                            Nombre de produits
                          </th>
                          <th class="text-right">
                            Montant
                          </th>
                          <th>-</th>
                          <th class="text-right">
                            -
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(order, i) in customer.orders"
                          :key="i"
                        >
                          <td class="text-center">
                            <router-link :to="{ name: 'orderDetails', params: { id: order.id } }">
                              #{{ order.reference }}
                            </router-link>
                          </td>
                          <td class="text-center">
                            {{ order.createdAt }}
                          </td>
                          <td class="text-center">
                            <StatusChip :status="order.status" />
                          </td>
                          <td class="text-center">
                            <div
                              v-for="(coupon, i) in order.coupons"
                              :key="'coupon' + i"
                            >
                              <router-link :to="{ name: 'couponDetails', params: { id: coupon.id } }">
                                {{ coupon.code }}
                              </router-link>
                            </div>
                            <div
                              v-for="(giftCard, i) in order.giftCards"
                              :key="'giftCard' + i"
                            >
                              <router-link :to="{ name: 'giftCardDetails', params: { id: giftCard.id } }">
                                {{ giftCard.code }}
                              </router-link>
                            </div>
                          </td>
                          <td class="text-right">
                            x {{ order.productCount }}
                          </td>
                          <td class="text-right">
                            {{ order.totalAmount }}
                          </td>
                          <td class="text-right">
                            <img
                              :src="order.shippingMethod.deliveryCarrierIconSrc"
                              :alt="order.shippingMethod.deliveryCarrier"
                              :title="order.shippingMethod.deliveryCarrier"
                              width="32"
                              height="32"
                            >
                          </td>
                          <td class="text-right">
                            <img
                              :src="order.paymentServiceProviderIconSrc"
                              :alt="order.paymentServiceProvider"
                              :title="order.paymentServiceProvider"
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
                <v-tab-item key="postalAddressEntries">
                  <div>
                    <span
                      v-if="customer.postalAddressBookEntries.length === 0"
                      class="font-weight-light font-italic"
                    >
                      pas d'adresses enregistées
                    </span>
                    <div
                      v-for="(postalAddressBookEntry, i) in customer.postalAddressBookEntries"
                      v-else
                      :key="i"
                    >
                      <div class="font-weight-light font-italic mb-2">
                        créée le {{ postalAddressBookEntry.createdAt }}
                      </div>
                      <v-row>
                        <v-col cols="auto">
                          <v-icon
                            v-if="postalAddressBookEntry.isDefault"
                            color="green"
                            aria-label="défault"
                          >
                            mdi-check-circle
                          </v-icon>
                          <v-icon
                            v-else
                            color="grey"
                          >
                            mdi-check-circle
                          </v-icon>
                        </v-col>
                        <v-col>
                          <div>
                            {{ formatFullName(postalAddressBookEntry.postalAddress) }}
                          </div>
                          <div>{{ postalAddressBookEntry.postalAddress.addressLine1 }}</div>
                          <div v-if="postalAddressBookEntry.postalAddress.addressLine2">
                            {{ postalAddressBookEntry.postalAddress.addressLine2 }}
                          </div>
                          <div>
                            {{ postalAddressBookEntry.postalAddress.postalCode }}
                            {{ postalAddressBookEntry.postalAddress.cityName }}
                          </div>
                          <div v-if="postalAddressBookEntry.postalAddress.phoneNumber">
                            {{ postalAddressBookEntry.postalAddress.phoneNumber }}
                          </div>
                        </v-col>
                      </v-row>
                      <v-divider
                        v-if="i !== Object.keys(customer.postalAddressBookEntries).length - 1"
                        class="my-4"
                      />
                    </div>
                  </div>
                </v-tab-item>
                <v-tab-item key="sizes">
                  <v-row>
                    <v-col
                      class="font-weight-medium"
                      cols="2"
                    >
                      Tailles haut
                    </v-col>
                    <v-col class="font-weight-light">
                      {{ customer.topSizes }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      class="font-weight-medium"
                      cols="2"
                    >
                      Tours de taille
                    </v-col>
                    <v-col class="font-weight-light">
                      {{ customer.waistSizes }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      class="font-weight-medium"
                      cols="2"
                    >
                      Tours de tête
                    </v-col>
                    <v-col class="font-weight-light">
                      {{ customer.headSizes }}
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      class="font-weight-medium"
                      cols="2"
                    >
                      Pointures
                    </v-col>
                    <v-col class="font-weight-light">
                      {{ customer.shoesSizes }}
                    </v-col>
                  </v-row>
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>
import { alpha3toAlpha2CountryCode, countryCodeEmoji } from '@/helpers/countries'
import { CustomerAction, CustomerGetter, CustomerMutation } from '@/store/types'
import AnonymizationModal from './AnonymizationModal'
import DeactivationModal from './DeactivationModal'
import { formatFullName } from '@/utils'
import { mapGetters } from 'vuex'
import Page from '../Layout/Page'
import StatusChip from '../Order/StatusChip'

export default {
  components: {
    AnonymizationModal,
    DeactivationModal,
    StatusChip,
    Page,
  },
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapGetters({
      loading: CustomerGetter.IsLoading,
      error: CustomerGetter.HasError,
      customer: CustomerGetter.Details,
    }),
    getCountryFlag() {
      return this.customer.countryCode ? countryCodeEmoji(alpha3toAlpha2CountryCode(this.customer.countryCode)) : null
    },
  },
  mounted() {
    this.$store.dispatch(CustomerAction.GetOne, this.$route.params.id)
  },
  methods: {
    formatFullName,
    openDeactivationModal() {
      this.$store.commit(CustomerMutation.OpenDeactivationModal)
    },
    openAnonymizationModal() {
      this.$store.commit(CustomerMutation.OpenAnonymizationModal)
    },
  },
}
</script>
