<template>
  <v-dialog
    v-model="isOpened"
    width="auto"
    @keydown.esc="close"
  >
    <v-card
      v-if="customer"
      max-width="700"
      min-width="500"
      data-testId="modal-anonymization"
    >
      <v-card-title>
        Anonymisation de {{ customer.firstName }} / {{ customer.emailAddress }}
      </v-card-title>
      <v-alert
        v-if="errors.internal"
        dense
        prominent
        type="error"
      >
        Une erreur inattendue s'est produite.
      </v-alert>
      <v-card-text>
        <v-alert
          border="left"
          type="error"
          colored-border
          elevation="2"
        >
          L'utilisateur <b>ne pourra plus se connecter</b>. Mais il est possible de créer un nouveau compte.
        </v-alert>
        <v-alert
          border="left"
          type="error"
          colored-border
          elevation="2"
        >
          Les <b>données personnelles</b> (nom, prénom, coordonnées, ... ) de l'utilisateur
          <b>seront anonymisées</b>.
        </v-alert>
        <v-alert
          border="left"
          type="error"
          colored-border
          elevation="2"
        >
          L'utilisateur <b>ne sera pas supprimé</b> des communications Marketing & promotionnelles.<br><br>
          Il est nécessaire de le supprimer depuis
          <a
            href="https://app.segment.com/imparfaite/privacy/settings/suppressed-users"
            target="_blank"
          >
            Segment
          </a> :<br>
          <pre>userId : {{ customer.id }}</pre>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          outlined
          @click="close()"
        >
          <v-icon left>
            mdi-close-circle-outline
          </v-icon>
          Annuler
        </v-btn>
        <v-spacer />
        <v-btn
          submit
          color="red darken-4"
          outlined
          @click="save()"
        >
          <v-icon left>
            mdi-incognito
          </v-icon>
          Anonymiser
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CustomerAction, CustomerGetter, CustomerMutation } from '@/store/types'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      isOpened: CustomerGetter.IsAnonymizationModalOpened,
      customer: CustomerGetter.Details,
      errors: CustomerGetter.Errors,
    }),
  },
  methods: {
    close() {
      this.$store.commit(CustomerMutation.CloseAnonymizationModal)
    },
    save() {
      this.$store.dispatch(CustomerAction.Anonymize, this.customer.id)
    },
  },
}
</script>
