<template>
  <div>
    <div v-if="fullName">
      {{ fullName }}
    </div>
    <div v-if="address.companyName">
      {{ address.companyName }}
    </div>
    <div>{{ address.addressLine1 }}</div>
    <div v-if="address.addressLine2">
      {{ address.addressLine2 }}
    </div>
    <div>
      {{ address.postalCode }}
      {{ address.cityName }}
    </div>
    <div>
      {{ getCountryName }} {{ getCountryFlag }}
    </div>
    <div v-if="address.phoneNumber">
      <v-divider class="phone-number__divider" />
      {{ address.phoneNumber }}
    </div>
  </div>
</template>

<script>
import { alpha3toAlpha2CountryCode, COUNTRY_NAMES, countryCodeEmoji } from '@/helpers/countries'
import { formatFullName } from '@/utils'

export default {
  props: {
    address: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    fullName() {
      return formatFullName(this.address)
    },
    getCountryName() {
      return COUNTRY_NAMES[this.address.countryCode]
    },
    getCountryFlag() {
      return countryCodeEmoji(alpha3toAlpha2CountryCode(this.address.countryCode))
    },
  },
}

</script>
<style lang="scss">
.phone-number__divider {
  margin: 10px 0;
  max-width: 100px;
}
</style>
