<template>
  <Page
    :loading="loading"
    :error="error"
    type="stats"
  >
    <template #title>
      Dashboard
    </template>
    <template>
      <div>
        <Stats
          title="Aujourd'hui"
          :stats-period="daily"
        />
        <Stats
          title="Ce mois-ci"
          :stats-period="monthly"
          class="pt-10"
        />
      </div>
    </template>
  </Page>
</template>

<script>
import { DashboardAction, DashboardGetter } from '@/store/types'
import { mapGetters } from 'vuex'
import Page from '../Layout/Page'
import Stats from './Stats.vue'

export default {
  components: {
    Stats,
    Page,
  },
  computed: {
    ...mapGetters({
      daily: DashboardGetter.GetDaily,
      monthly: DashboardGetter.GetMonthly,
      loading: DashboardGetter.IsLoading,
      error: DashboardGetter.HasError,
    }),
  },
  mounted() {
    this.$store.dispatch(DashboardAction.GetAll)
  },
}
</script>
