<template>
  <div>
    <v-simple-table>
      <template #default>
        <thead>
          <tr>
            <th>Date</th>
            <th>Auteur</th>
            <th>Motif</th>
            <th class="text-right">
              Produits
            </th>
            <th class="text-right">
              Shipping
            </th>
            <th class="text-right">
              Contrôle Qualité
            </th>
            <th class="text-right">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(orderRefund, i) in orderRefunds"
            :key="i"
          >
            <td>{{ orderRefund.createdAt }}</td>
            <td>{{ orderRefund.refundedBy }}</td>
            <td>{{ reasonLabel(orderRefund) }}</td>
            <td class="text-right">
              {{ orderRefund.productRefunds }}
              <v-tooltip
                v-if="orderRefund.productRefundInfos.length"
                bottom
                color="secondary"
              >
                <template #activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    class="mb-1"
                    v-on="on"
                  >
                    <v-icon aria-hidden="false">
                      mdi-help-circle-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-list
                  color="secondary"
                  dense
                >
                  <v-list-item
                    v-for="(productRefundInfo, i) in orderRefund.productRefundInfos"
                    :key="i"
                  >
                    {{ productRefundInfo.product }}: {{ productRefundInfo.amount }}
                  </v-list-item>
                </v-list>
              </v-tooltip>
            </td>
            <td class="text-right">
              {{ orderRefund.shippingRefund }}
            </td>
            <td class="text-right">
              {{ orderRefund.qualityControlRefund }}
            </td>
            <td class="text-right">
              {{ orderRefund.refundedAmount }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { REFUND_REASONS }  from '@/helpers/order'

export default {
  props: {
    orderRefunds: {
      type: Array,
      required: true,
    },
  },
  methods: {
    reasonLabel(orderRefund) {
      return REFUND_REASONS[orderRefund.reason]
    },
  },
}
</script>
