<template>
  <Page type="list">
    <template #title>
      Outils
    </template>
    <template>
      <v-row>
        <v-col cols="md-6">
          <v-card
            :loading="optIn.import.loading"
            class="mx-auto"
          >
            <v-card-title>
              <v-icon class="mr-4">
                mdi-email-sync-outline
              </v-icon>
              Import d'emails OptIn par lot
            </v-card-title>
            <v-card-text>
              <v-alert
                border="left"
                type="info"
                colored-border
                elevation="2"
                class="mb-12"
              >
                Stockage & Envoi d'une liste d'email afin de les OptIn à la Newsletter
                <ul>
                  <li>Un fichier CSV avec une seule colonne contenant les emails</li>
                  <li>Il n'est pas possible d'importer plusieurs fois le même (nom de) fichier.</li>
                </ul>
              </v-alert>
              <v-form @submit.prevent="optInUpload()">
                <v-row>
                  <v-col
                    cols="12"
                    sm="10"
                  >
                    <v-file-input
                      v-model="optIn.upload.file"
                      :loading="optIn.upload.loading"
                      prepend-icon="mdi-file-delimited-outline"
                      accept="text/csv"
                      label="Sélectionner le fichier à importer"
                      dense
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                  >
                    <v-btn
                      :loading="optIn.upload.loading"
                      color="primary"
                      type="submit"
                    >
                      Envoyer
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-alert
                      v-if="optIn.upload.error"
                      border="left"
                      type="error"
                      colored-border
                      elevation="2"
                    >
                      {{ optIn.upload.error }}
                    </v-alert>
                    <v-alert
                      v-if="optIn.import.filename && !optIn.import.success"
                      border="left"
                      type="info"
                      colored-border
                      elevation="2"
                    >
                      Vous allez procéder à l'import du fichier
                      <strong>{{ optIn.import.filename }}.</strong>
                    </v-alert>
                    <v-alert
                      v-if="optIn.import.success"
                      border="left"
                      type="success"
                      colored-border
                      elevation="2"
                    >
                      Le fichier  <strong>{{ optIn.import.filename }}.</strong> a bien été importé
                    </v-alert>
                    <v-alert
                      v-if="optIn.import.error"
                      border="left"
                      type="error"
                      colored-border
                      elevation="2"
                    >
                      Le fichier <strong>{{ optIn.import.filename }}.</strong> n'a pas été importé
                    </v-alert>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="red accent-4"
                left
                outlined
                @click="optInReset"
              >
                <v-icon>mdi-close</v-icon>Annuler
              </v-btn>
              <v-spacer />
              <v-select
                v-model="source"
                class="mr-5"
                style="max-width: 150px"
                :items="availableSources"
                hide-details
                clearable
                outlined
                dense
                label="Source"
              />
              <v-btn
                :disabled="!unlockImport"
                color="teal accent-4"

                outlined
                @click="optInImportFile()"
              >
                <v-icon>mdi-file-import-outline</v-icon>Importer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="md-6" />
      </v-row>
    </template>
  </Page>
</template>

<script>
import http from '../../http'
import Page from '../Layout/Page'

export default {
  components: {
    Page,
  },

  data() {
    return {
      optIn: {
        upload: {
          done: false,
          error: null,
          file: null,
          loading: false,
        },
        import: {
          done: false,
          error: false,
          filename: null,
          loading: false,
          success: false,
        },
      },
      availableSources: [
        'Autres',
        'MetaAds',
        'UBU',
        'Concours',
      ],
      source: 'Autres',
    }
  },
  computed: {
    unlockImport: function() {
      if (this.optIn.import.loading === true) {
        return false
      }
      
      if (this.optIn.import.done === true) {
        return false
      }
      
      return this.optIn.upload.done === true && this.optIn.import.done === false
    },
  },
  methods: {
    optInReset() {
      this.optIn.upload.done = false
      this.optIn.upload.error = null
      this.optIn.upload.file = null
      this.optIn.upload.loading = false
      this.optIn.import.error = false
      this.optIn.import.filename = null
      this.optIn.import.loading = false
      this.optIn.import.success = false
      this.optIn.import.done = false
    },
    async optInUpload() {
      if ( this.optIn.upload.file === null) {
        return
      }
      this.optIn.upload.loading = true
      this.optIn.upload.error = null

      const formData = new FormData()
      formData.append('file', this.optIn.upload.file)

      await http.post(
        '/upload/file/optin',
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      ).then((response) => {
        // eslint-disable-next-line no-console
        console.log(response.data)

        this.optIn.upload.done = true
        this.optIn.import.filename = this.optIn.upload.file.name
      }).catch((error) => {
        if (error.response.status === 409) {
          this.optIn.upload.error = error.response.data.detail
        } else {
          // eslint-disable-next-line no-console
          console.log(error)
        }
      }).finally(() => {
        this.optIn.upload.loading = false
      })
    },
    async optInImportFile() {
      if ( this.optIn.import.filename === null) {
        return
      }

      this.optIn.import.loading = true
      await http.post('/graphql', JSON.stringify({
        query: `
            mutation ImportFile($source: String!, $filename: String!){
                importOptInFile(source: $source, filename: $filename)
            }`,
        variables: {
          filename: this.optIn.import.filename,
          source: this.source,
        },
      })).then(() => {
        this.optIn.import.success = true
        this.optIn.import.done = true
        this.optIn.upload.file = null
        this.optIn.import.loading = false
      }).catch(() => {
        this.optIn.import.error = true
        this.optIn.import.done = true
        this.optIn.upload.file = null
        this.optIn.import.loading = false
      })
    },
  },
}
</script>
