<template>
  <Page
    :loading="loading"
    :error="error"
  >
    <template
      v-if="order"
      #title
    >
      <v-card
        outlined
        elevation="2"
      >
        <v-card-title class="grey lighten-4">
          <h2>Détails de la commande {{ order.reference }}</h2>
          <v-spacer />
          <StatusChip :status="order.status" />
        </v-card-title>
      </v-card>
    </template>
    <template v-if="order">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-card height="100%">
            <v-card-title>
              Général
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  class="font-weight-medium"
                  cols="3"
                >
                  Réalisée le
                </v-col>
                <v-col class="font-weight-light">
                  {{ order.createdAt }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="font-weight-medium"
                  cols="3"
                >
                  Client
                </v-col>
                <v-col class="font-weight-light">
                  <router-link :to="{ name: 'customerDetails', params: { id: order.customer.id } }">
                    {{ order.customerName || order.customer.emailAddress }}
                  </router-link>
                </v-col>
              </v-row>
              <template v-if="order.paidAt">
                <v-divider class="my-4" />
                <v-row>
                  <v-col
                    class="font-weight-medium"
                    cols="3"
                  >
                    Payée le:
                  </v-col>
                  <v-col
                    class="font-weight-light"
                    cols="9"
                  >
                    {{ order.paidAt }}<br>
                    via
                    <span class="font-weight-bold">
                      {{ order.paymentServiceProvider }}
                    </span>
                    <span class="font-weight-light caption">
                      ({{ order.paymentId || "????" }})
                    </span>
                  </v-col>
                </v-row>
              </template>
              <v-divider class="my-4" />
              <v-row>
                <v-col
                  class="font-weight-medium"
                  cols="3"
                >
                  Dernière modification
                </v-col>
                <v-col
                  class="font-weight-light"
                  cols="9"
                >
                  {{ order.updatedAt }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>
              Contexte
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col
                  class="font-weight-medium"
                  cols="3"
                >
                  Source
                </v-col>
                <v-col class="font-weight-light">
                  {{ (order.context ? order.context.libraryName : null) || ' - ' }}
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="font-weight-medium"
                  cols="3"
                >
                  Version
                </v-col>
                <v-col class="font-weight-light">
                  {{ (order.context ? order.context.libraryVersion : null) || ' - ' }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card height="100%">
            <v-card-title>
              Livraison
            </v-card-title>
            <v-card-text>
              <!-- only display client address if the delivery address is a service point -->
              <v-row v-if="order.deliveryAddress.servicePointId">
                <v-col class="font-weight-medium">
                  Adresse de contact
                </v-col>
                <v-col class="font-weight-light">
                  <Address :address="order.contactAddress" />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Adresse de livraison
                  <div v-if="order.deliveryAddress.servicePointId">
                    (point relais)
                  </div>
                </v-col>
                <v-col class="font-weight-light">
                  <Address :address="order.deliveryAddress" />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  class="font-weight-medium"
                  cols="md-6"
                >
                  Mode de livraison
                </v-col>
                <v-col
                  class="font-weight-light"
                  cols="md-4"
                >
                  {{ order.shippingMethod.deliveryMethod }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Type de livraison
                </v-col>
                <v-col class="font-weight-light">
                  {{ order.shippingMethod.deliveryType }}
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col
                  class="font-weight-medium"
                  cols="md-6"
                >
                  Transporteur
                </v-col>
                <v-col
                  class="font-weight-light"
                  cols="md-4"
                >
                  {{ order.shippingMethod.deliveryCarrier }}
                </v-col>
                <v-col
                  class="font-weight-light text-left"
                  cols="md-2"
                >
                  <img
                    :src="order.shippingMethod.deliveryCarrierIconSrc"
                    :alt="order.shippingMethod.deliveryCarrier"
                    :title="order.shippingMethod.deliveryCarrier"
                    width="32"
                    height="32"
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-card
            height="100%"
            data-testid="info-amounts"
          >
            <v-card-title>
              Montants
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col class="font-weight-medium">
                  Produits
                </v-col>
                <v-col class="font-weight-light text-right">
                  {{ order.priceDetails.products }}
                </v-col>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Livraison
                </v-col>
                <v-col class="font-weight-light text-right">
                  {{ order.priceDetails.shipping }}
                </v-col>
              </v-row>
              <v-row v-if="order.priceDetails.qualityControl">
                <v-col class="font-weight-medium">
                  Contrôle qualité
                </v-col>
                <v-col class="font-weight-light text-right">
                  {{ order.priceDetails.qualityControl }}
                </v-col>
              </v-row>
              <v-row v-if="order.priceDetails.reductions">
                <v-col class="font-weight-medium">
                  Reductions
                </v-col>
                <v-col class="font-weight-light text-right">
                  {{ order.priceDetails.reductions }}
                </v-col>
              </v-row>
              <v-row
                v-if="order.priceDetails.discounts.length > 0"
                class="font-weight-light caption"
              >
                <v-list dense>
                  <v-list-item
                    v-for="discount in order.priceDetails.discounts"
                    :key="discount.id"
                  >
                    <router-link
                      v-if="discount.type === 'COUPON'"
                      :to="{ name: 'couponDetails', params: { id: discount.id } }"
                    >
                      {{ discount.code }}
                    </router-link>
                    <span v-else>
                      {{ discount.code }}
                    </span>
                    : {{ discount.amount }}
                  </v-list-item>
                </v-list>
              </v-row>
              <v-row>
                <v-col class="font-weight-medium">
                  Total
                </v-col>
                <v-col class="font-weight-light text-right">
                  {{ order.priceDetails.total }}
                </v-col>
              </v-row>
              <v-row
                v-if="order.refundedAmount"
                class="font-weight-bold red--text"
              >
                <v-col class="font-weight-medium">
                  Remboursé
                </v-col>
                <v-col class="font-weight-bold text-right">
                  {{ order.refundedAmount }}
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          data-testid="info-tab"
          cols="12"
          md="12"
        >
          <v-card>
            <v-card-text>
              <v-tabs v-model="tab">
                <v-tab key="items">
                  Articles
                </v-tab>
                <v-tab key="refunds">
                  Remboursements
                </v-tab>
              </v-tabs>
              <v-tabs-items
                v-model="tab"
                class="my-4"
              >
                <v-tab-item key="articles">
                  <DetailItems :order="order" />
                </v-tab-item>
                <v-tab-item key="refunds">
                  <DetailRefunds :order-refunds="orderRefunds" />
                </v-tab-item>
              </v-tabs-items>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
  </Page>
</template>

<script>

import { OrderAction, OrderGetter } from '@/store/types'
import Address from './Address'
import DetailItems  from './Detail/Items'
import DetailRefunds  from './Detail/Refunds'
import { formatFullName } from '@/utils'
import { mapGetters } from 'vuex'
import Page from '../Layout/Page'
import StatusChip from './StatusChip'

export default {
  components: {
    DetailItems,
    DetailRefunds,
    Address,
    Page,
    StatusChip,
  },
  data() {
    return {
      tab: null,
    }
  },
  computed: {
    ...mapGetters({
      loading: OrderGetter.IsLoading,
      error: OrderGetter.HasError,
      order: OrderGetter.Details,
      orderRefunds: OrderGetter.OrderRefunds,
    }),
  },
  mounted() {
    this.$store.dispatch(OrderAction.GetOne, this.$route.params.id)
    this.$store.dispatch(OrderAction.GetOrderRefunds, this.$route.params.id)
  },
  methods: {
    formatFullName,
  },
}
</script>
