<template>
  <div>
    <h2>
      {{ title }}
      <v-tooltip
        bottom
        color="secondary"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            icon
            x-small
            v-bind="attrs"
            class="mb-1"
            v-on="on"
          >
            <v-icon aria-hidden="false">
              mdi-help-circle-outline
            </v-icon>
          </v-btn>
        </template>
        <div class="black--text">
          Du <br>
          {{ statsPeriod.dateRange.from }} au {{ statsPeriod.dateRange.to }}
          <br>
          Comparé au <br>
          {{ statsPeriod.compareWith.from }} au {{ statsPeriod.compareWith.to }}
        </div>
      </v-tooltip>
    </h2>
    <v-row>
      <v-col
        v-for="(stat, index) in statsPeriod.stats"
        :key="index"
      >
        <v-card>
          <v-card-text>
            <div class="stat-title">
              {{ getTitle(stat.type) }}
            </div>
            <v-row>
              <v-col class="stat-value">
                {{ stat.value }}
              </v-col>
              <v-col
                v-if="stat.evolution"
                align="right"
              >
                <b :style="{color: getEvolutionColor(stat.evolution.progression)}">{{ stat.evolution.value }}</b>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    statsPeriod: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTitle(type) {
      switch (type) {
      case 'gmv':
        return 'GMV'
      case 'orders':
        return 'Commandes'
      case 'items':
        return 'Produits Vendus'
      case 'refundedAmount':
        return 'Remboursements'
      }
    },
    getEvolutionColor(progression) {
      if (!progression) {
        return 'black'
      }
      if (progression === '+') {
        return '#80CAB0'
      }

      return '#EA1717'
    },
  },
}
</script>

<style>
.stat-title {
  color: #737373;
  font-size: 15px;
  font-weight: bold;
  padding-bottom: 5px;
}

.stat-value {
  color: black;
  font-size: 25px;
  font-weight: bold;
}
</style>
