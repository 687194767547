<template>
  <Page
    :loading="loading"
    :error="error"
    type="list"
  >
    <template #title>
      Clients
    </template>
    <template #no-reload>
      <v-sheet
        rounded
        elevation="3"
      >
        <v-form>
          <v-container fluid>
            <v-row dense>
              <v-col
                cols="10"
                md="3"
              >
                <v-text-field
                  v-model="filters.search"
                  clearable
                  dense
                  hide-details
                  label="Rechercher"
                  outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-sheet>
    </template>
    <template v-if="customers.length">
      <v-sheet
        rounded
        elevation="3"
      >
        <v-simple-table>
          <thead>
            <tr>
              <th>Id</th>
              <th>Nom</th>
              <th>Email</th>
              <th>Date de création</th>
              <th class="text-right">
                -
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer in customers"
              :key="customer.id"
              tabIndex="0"
              @keypress.enter="() => onCustomerClick(customer.id)"
            >
              <td>
                <router-link :to="{ name: 'customerDetails', params: { id: customer.id } }">
                  #{{ customer.shortId }}
                </router-link>
              </td>
              <td>{{ customer.fullName }}</td>
              <td>{{ customer.emailAddress }}</td>
              <td>{{ customer.createdAt }}</td>
              <td class="text-right">
                <v-icon
                  v-if="customer.deactivated"
                  class="mr-2"
                  color="red darken-3"
                >
                  mdi-account-lock-outline
                </v-icon>
                <v-icon
                  v-if="customer.anonymizedAt !== null"
                  class="mr-2"
                  color="red darken-3"
                >
                  mdi-incognito
                </v-icon>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <div
          v-if="nbPages"
          class="text-center mt-8"
        >
          <v-pagination
            v-model="page"
            :length="nbPages"
            :total-visible="8"
            rounded="circle"
          />
        </div>
      </v-sheet>
    </template>
  </Page>
</template>

<script>
import { CustomerAction, CustomerGetter } from '@/store/types'
import { mapGetters } from 'vuex'
import Page from '../Layout/Page'

let debounceTimerId

export default {
  components: {
    Page,
  },

  data () {
    return {
      filters: this.$store.getters[CustomerGetter.Filters],
    }
  },
  computed: {
    ...mapGetters({
      customers: CustomerGetter.List,
      loading: CustomerGetter.IsLoading,
      error: CustomerGetter.HasError,
      nbPages: CustomerGetter.TotalPages,
    }),
    page: {
      get() { return this.$store.getters[CustomerGetter.Page] },
      set(value) { this.$store.dispatch(CustomerAction.GetAll, value) },
    },
  },
  watch: {
    'filters.search'() {
      clearTimeout(debounceTimerId)
      debounceTimerId = setTimeout(() => {
        this.$store.dispatch(CustomerAction.ApplyFilters, this.filters)
      }, 600)
    },
  },
  mounted() {
    this.$store.dispatch(CustomerAction.GetAll)
  },
  methods: {
    onCustomerClick(id) {
      this.$router.push({ name: 'customerDetails', params: { id } })
    },
  },

}
</script>
