<template>
  <v-navigation-drawer
    app
    mobile-breakpoint="960"
    :width="280"
    dark
  >
    <v-container>
      <v-img
        alt="Imparfaite"
        max-width="150px"
        height="30"
        style="margin: 0 auto; padding: 10px 0;"
        :src="require('../../assets/logo.white.svg')"
      />
    </v-container>
    <v-divider />
    <div class="ma-4 accent--text text-center">
      {{ user }}
    </div>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="([text, route, icon], i) in items"
        :key="i"
        :to="{name: route}"
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template #append>
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Se déconnecter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>

import { AuthGetter, AuthMutation } from '@/store/types'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      items: [
        ['Dashboard', 'dashboard', 'mdi-finance'],
        ['Commandes', 'orderList', 'mdi-basket-outline'],
        ['Clients', 'customerList', 'mdi-account-outline'],
        ['Codes de reduction', 'couponList', 'mdi-file-percent-outline'],
        ['Cartes Cadeaux', 'giftCardList', 'mdi-gift-outline'],
        ['Outils', 'toolsBoard', 'mdi-tools'],
      ],
    }
  },
  computed: {
    ...mapGetters({
      user: AuthGetter.User,
    }),
  },
  methods: {
    logout() {
      this.$store.commit(AuthMutation.Logout)
    },
  },
}
</script>
