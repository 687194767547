<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-4">
        <h1><slot name="title" /></h1>
      </v-col>
    </v-row>
    <slot name="no-reload" />
    <LoadingState :loading="loading" />
    <v-alert
      v-if="error"
      dense
      prominent
      type="error"
    >
      Une erreur inattendue s'est produite.
    </v-alert>
    <template v-if="loading">
      <v-skeleton-loader
        v-if="'list' === type"
        type="table"
        :loading="loading"
      />
      <v-skeleton-loader
        v-if="'card' === type"
        type="card"
        :loading="loading"
      />
      <div v-else>
        <v-row>
          <v-col>
            <v-skeleton-loader
              type="heading"
              :loading="loading"
              class="pb-5"
            />
            <v-skeleton-loader
              type="image"
              :loading="loading"
              max-height="100"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-skeleton-loader
              type="heading"
              :loading="loading"
              class="pb-5"
            />
            <v-skeleton-loader
              type="image"
              :loading="loading"
              max-height="100"
            />
          </v-col>
        </v-row>
      </div>
    </template>
    <slot v-else />
    <!-- just to keep the opened form modal states -->
    <slot name="form-modals" />
  </v-container>
</template>

<script>
import LoadingState from './LoadingState'

export default {
  components: {
    LoadingState,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    error: {
      type: Boolean,
      default: false,
    },
    type:  {
      type: String,
      default: null,
    },
  },
}
</script>
