<template>
  <v-dialog
    v-model="isOpened"
    persistent
    width="1000"
  >
    <v-card>
      <v-alert
        v-if="errors.global"
        dense
        prominent
        type="error"
      >
        {{ errors.global }}
      </v-alert>
      <form @submit.prevent="save">
        <v-card-text>
          <v-container>
            <v-card>
              <v-card-title>Général</v-card-title>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="code"
                      :readonly="edit"
                      :disabled="edit"
                      label="Code"
                      required
                      :error-messages="errors.code"
                    />
                  </v-col>
                  <v-col>
                    <v-switch
                      v-model="isAuto"
                      label="auto"
                    />
                  </v-col>
                </v-row>
                <v-row cols="12">
                  <v-col cols="6">
                    <v-text-field
                      v-model="offerNameFr"
                      label="Nom de l'offre FR"
                      type="string"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="offerNameEn"
                      label="Nom de l'offre EN"
                      type="string"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-textarea
                      v-model="offerDescriptionFr"
                      label="Description de l'offre FR"
                      type="string"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-textarea
                      v-model="offerDescriptionEn"
                      label="Description de l'offre EN"
                      type="string"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-container>
          <v-container>
            <v-row>
              <!------------------------------------------------------------------------------------------------------
                -------------------------------------------- EFFECT -------------------------------------------------
                ----------------------------------------------------------------------------------------------------->
              <v-container>
                <v-card>
                  <v-card-title>Effets</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-radio-group
                          v-model="selectedEffect"
                          hide-details="auto"
                          row
                        >
                          <v-radio
                            label="Montant"
                            :value="couponEffects.amount"
                          />
                          <v-radio
                            label="Pourcentage"
                            :value="couponEffects.percent"
                          />
                          <v-radio
                            label="Livraison gratuite"
                            :value="couponEffects.freeShipping"
                          />
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <template v-if="selectedEffect === couponEffects.amount">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model.number="reductionAmount"
                            label="Montant"
                            type="number"
                            min="1"
                            required
                            :error-messages="errors.reductionAmount"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="reductionCurrency"
                            label="Devise"
                            disabled
                          />
                        </v-col>
                        <v-col>
                          <v-select
                            v-model="selectedEffectTarget"
                            :items="effectTargets"
                            item-value="name"
                            filled
                            label="s'applique sur"
                            dense
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="effectTargetStoreId"
                            label="Limité à la boutique"
                            :error-messages="errors.effectTargetStoreId"
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-else-if="selectedEffect === couponEffects.percent">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="reductionPercentPercent"
                            label="Pourcentage"
                            append-text="%"
                            min="1"
                            type="number"
                            :error-messages="errors.reductionPercent"
                          />
                        </v-col>
                        <v-col>
                          <v-select
                            v-model="selectedEffectTarget"
                            :items="effectTargets"
                            item-value="name"
                            filled
                            label="s'applique sur"
                            dense
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="effectTargetStoreId"
                            label="Limité à la boutique"
                            :error-messages="errors.effectTargetStoreId"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="reductionPercentCeilingAmount"
                            label="Montant plafond"
                            type="number"
                            :error-messages="errors.ceilingAmount"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="reductionPercentCeilingCurrency"
                            label="Devise"
                            disabled
                          />
                        </v-col>
                      </v-row>
                    </template>
                    <template v-if="selectedEffect === couponEffects.freeShipping">
                      <v-row>
                        <v-col>
                          <v-text-field
                            v-model="freeShippingCeilingAmount"
                            label="Montant plafond"
                            type="number"
                            :error-messages="errors.ceilingAmount"
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="freeShippingCeilingCurrency"
                            label="Devise"
                            disabled
                          />
                        </v-col>
                        <v-col>
                          <v-text-field
                            v-model="effectTargetStoreId"
                            label="Limité à la boutique"
                            :error-messages="errors.effectTargetStoreId"
                          />
                        </v-col>
                      </v-row>
                    </template>
                  </v-card-text>
                </v-card>
              </v-container>

              <!------------------------------------------------------------------------------------------------------
                -------------------------------------------- CONDITIONS ----------------------------------------------
                ----------------------------------------------------------------------------------------------------->
              <v-container>
                <v-card>
                  <v-card-title>Conditions</v-card-title>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="expiresAt"
                          label="Date d'expiration"
                          type="date"
                          :error-messages="errors['condition.expiresAt']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model.number="minimalAmount"
                          label="Montant minimum"
                          min="0"
                          type="number"
                          :error-messages="errors['condition.minimalAmount']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model.number="nbOrders"
                          label="Nombre exact de commandes nécessaire pour bénéficier du coupon"
                          hint="Uniquement pour la 1ère commande, mettre 0"
                          min="0"
                          type="number"
                          :error-messages="errors['condition.numberOfOrders']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model.number="newsletterSubscriptionDays"
                          label="Nombre de jours max depuis l'inscription à la newsletter"
                          min="0"
                          type="number"
                          :error-messages="errors['condition.newsletterSubscriptionDays']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="customerId"
                          label="Id client"
                          :error-messages="errors['condition.customerId']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="appOnly"
                          label="Uniquement pour l'Appli"
                          :error-messages="errors['condition.appOnly']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="selectedCountries"
                          label="Pays"
                          :items="countries"
                          multiple
                          :error-messages="errors['condition.countries']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-select
                          v-model="selectedDeliveryCarrier"
                          clearable
                          label="Livraison"
                          :items="deliveryCarriers"
                          :error-messages="errors['condition.deliveryCarrier']"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model.number="numberOfProducts"
                          label="Nombre min de produits"
                          min="0"
                          type="number"
                          :error-messages="errors['condition.numberOfProducts.nb']"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="storeIdForNumberOfProducts"
                          label="de la boutique"
                          :error-messages="errors['condition.numberOfProducts.storeId']"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            raised
            @click="close()"
          >
            <v-icon left>
              mdi-close-circle-outline
            </v-icon>
            Fermer
          </v-btn>
          <v-spacer />
          <v-btn
            submit
            color="green darken-3"
            class="white--text"
            @click="save()"
          >
            <v-icon left>
              mdi-content-save-all-outline
            </v-icon>
            Enregistrer
          </v-btn>
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>

import { CouponAction, CouponGetter, CouponMutation } from '@/store/types'
import { COUNTRY_CODES } from '@/helpers/countries'
import { effects as couponEffects } from '../../helpers/coupon'
import { mapGetters } from 'vuex'

export default {
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      tab: null,
      effectTargets: [
        {
          name: 'PRODUCT',
          text: 'Produits',
        },
        {
          name: 'SHIPPING',
          text: 'Livraison',
        },
      ],
      selectedEffect: couponEffects.amount,
      id: '',
      code: '',
      reductionAmount: 1,
      reductionCurrency: 'EUR',
      reductionPercentPercent: 1,
      freeShippingCeilingAmount: null,
      freeShippingCeilingCurrency: 'EUR',
      reductionPercentCeilingAmount: null,
      reductionPercentCeilingCurrency: 'EUR',
      expiresAt: null,
      customerId: null,
      minimalAmount: null,
      appOnly: false,
      daysSinceLastOrder: null,
      nbOrders: null,
      newsletterSubscriptionDays: null,
      couponEffects,
      isAuto: false,
      selectedEffectTarget: 'PRODUCT',
      selectedCountries: [],
      countries: COUNTRY_CODES.map((c) => c.alpha3).sort(),
      selectedDeliveryCarrier: null,
      deliveryCarriers: [
        'MONDIAL_RELAY',
        'CHRONOPOST',
      ],
      effectTargetStoreId: null,
      numberOfProducts: null,
      storeIdForNumberOfProducts: null,
      offerNameFr: null,
      offerNameEn: null,
      offerDescriptionFr: null,
      offerDescriptionEn: null,
    }
  },
  computed: {
    ...mapGetters({
      isAddOpened: CouponGetter.IsAddModalOpened,
      isEditOpened: CouponGetter.IsEditModalOpened,
      coupon: CouponGetter.RawCoupon,
      errors: CouponGetter.CreationErrors,
    }),
    isOpened () {
      return this.isAddOpened || this.isEditOpened
    },
  },
  watch: {
    selectedEffect() {
      this.$store.dispatch(CouponAction.ResetCreationErrors)
    },
    isEditOpened (opened) {
      if (!opened) {
        return
      }
      if (!this.edit) {
        return
      }

      this.id = this.coupon.id
      this.code = this.coupon.code
      if (null !== this.coupon.effect.freeShipping) {
        this.selectedEffect = couponEffects.freeShipping
        if (null !== this.coupon.effect.freeShipping.ceilingAmount) {
          this.freeShippingCeilingAmount = this.coupon.effect.freeShipping.ceilingAmount.amount / 100
          this.freeShippingCeilingCurrency = this.coupon.effect.freeShipping.ceilingAmount.currency
        }
      } else if (null !== this.coupon.effect.reductionAmount) {
        this.selectedEffect = couponEffects.amount
        this.reductionAmount = this.coupon.effect.reductionAmount.amount / 100
        this.reductionCurrency = this.coupon.effect.reductionAmount.currency
      } else if (null !== this.coupon.effect.reductionPercent) {
        this.selectedEffect = couponEffects.percent
        this.reductionPercentPercent = this.coupon.effect.reductionPercent.percent
        if (null !== this.coupon.effect.reductionPercent.ceilingAmount) {
          this.reductionPercentCeilingAmount = this.coupon.effect.reductionPercent.ceilingAmount.amount / 100
          this.reductionPercentCeilingCurrency = this.coupon.effect.reductionPercent.ceilingAmount.currency
        }
      } else {
        throw new Error('unknown coupon effect')
      }
      this.selectedEffectTarget = this.coupon.effect.target
      this.effectTargetStoreId = this.coupon.effect.targetStoreId

      this.appOnly = this.coupon.condition.appOnly
      this.customerId = this.coupon.condition.customerId
      this.expiresAt = null !== this.coupon.condition.expiresAt
        ? this.coupon.condition.expiresAt.split('T')[0]
        : null
      this.minimalAmount = this.coupon.condition.minimalAmount?.amount
        ? this.coupon.condition.minimalAmount?.amount / 100
        : null
      this.nbOrders = this.coupon.condition.numberOfOrders
      this.newsletterSubscriptionDays = this.coupon.condition.newsletterSubscriptionDays
      this.selectedCountries = this.coupon.condition.countries || []
      this.selectedDeliveryCarrier = this.coupon.condition.deliveryCarrier
      this.isAuto = this.coupon.isAuto
      this.numberOfProducts = this.coupon.condition.numberOfProducts?.nb
      this.storeIdForNumberOfProducts = this.coupon.condition.numberOfProducts?.storeId
      this.offerNameFr = this.coupon.offerNameFr
      this.offerNameEn = this.coupon.offerNameEn
      this.offerDescriptionFr = this.coupon.offerDescriptionFr
      this.offerDescriptionEn = this.coupon.offerDescriptionEn
    },
  },
  methods: {
    close() {
      this.$store.commit(CouponMutation.CloseModal)
      this.tab = null
      this.effectTargets= [
        {
          name: 'PRODUCT',
          text: 'Produits',
        },
        {
          name: 'SHIPPING',
          text: 'Livraison',
        },
      ]
      this.id= ''
      this.code= ''
      this.reductionAmount= 1
      this.reductionCurrency= 'EUR'
      this.reductionPercentPercent= 1
      this.freeShippingCeilingAmount= null
      this.freeShippingCeilingCurrency= 'EUR'
      this.reductionPercentCeilingAmount= null
      this.reductionPercentCeilingCurrency= 'EUR'
      this.expiresAt= null
      this.customerId= null
      this.minimalAmount= null
      this.appOnly= false
      this.daysSinceLastOrder= null
      this.nbOrders= null
      this.newsletterSubscriptionDays= null
      this.isAuto= false
      this.selectedEffectTarget= 'PRODUCT'
      this.selectedCountries= []
      this.countries= COUNTRY_CODES.map((c) => c.alpha3).sort()
      this.selectedDeliveryCarrier= null
      this.deliveryCarriers= [
        'MONDIAL_RELAY',
        'CHRONOPOST',
      ]
      this. effectTargetStoreId= null
      this.numberOfProducts= null
      this.storeIdForNumberOfProducts= null
      this.offerNameFr= null
      this.offerNameEn= null
      this.offerDescriptionFr= null
      this.offerDescriptionEn= null
    },
    save() {
      let offerName = []
      if (this.offerNameFr) {
        offerName.push({ locale: 'FR', text: this.offerNameFr })
      }
      if (this.offerNameEn) {
        offerName.push({ locale: 'EN', text: this.offerNameEn })
      }
      let offerDescription = []
      if (this.offerDescriptionFr) {
        offerDescription.push({ locale: 'FR', text: this.offerDescriptionFr })
      }
      if (this.offerDescriptionEn) {
        offerDescription.push({ locale: 'EN', text: this.offerDescriptionEn })
      }

      let coupon = {
        code: this.code,
        effectTargetStoreId: this.effectTargetStoreId?.trim() || null,
        isAuto: this.isAuto,
        offerName: offerName.length ? offerName : null,
        offerDescription: offerDescription.length ? offerDescription : null,
        condition: {
          customerId: this.customerId,
          minimalAmount: parseInt(this.minimalAmount) * 100,
          appOnly: this.appOnly,
          expiresAt: this.expiresAt ? this.expiresAt + 'T00:00:00+00:00' : null,
          numberOfOrders: this.nbOrders ? parseInt(this.nbOrders) : null,
          newsletterSubscriptionDays: this.newsletterSubscriptionDays
            ? parseInt(this.newsletterSubscriptionDays) : null,
          countries: this.selectedCountries.length ? this.selectedCountries : null,
          deliveryCarrier: this.selectedDeliveryCarrier?.trim() || null,
          numberOfProducts: !parseInt(this.numberOfProducts) ? null : {
            nb: parseInt(this.numberOfProducts),
            storeId: this.storeIdForNumberOfProducts,
          },
        },
      }

      switch (this.selectedEffect) {
      case couponEffects.amount:
        coupon.reductionAmount = parseInt(this.reductionAmount) * 100
        coupon.reductionCurrency = this.reductionCurrency
        coupon.effectTarget = this.selectedEffectTarget
        break
      case couponEffects.percent:
        coupon.ceilingAmount  = this.reductionPercentCeilingAmount
          ? parseInt(this.reductionPercentCeilingAmount) * 100 : null
        coupon.ceilingCurrency = this.reductionPercentCeilingCurrency
        coupon.reductionPercent = parseInt(this.reductionPercentPercent)
        coupon.effectTarget = this.selectedEffectTarget
        break
      case couponEffects.freeShipping:
        coupon.ceilingAmount  = this.freeShippingCeilingAmount
          ? parseInt(this.freeShippingCeilingAmount) * 100 : null
        coupon.ceilingCurrency = this.freeShippingCeilingCurrency
        break
      }
      if (this.edit) {
        coupon = { id: this.id, ...coupon }
        this.$store.dispatch(CouponAction.Update, { coupon, effect: this.selectedEffect } )
      } else {
        this.$store.dispatch(CouponAction.Create, { coupon, effect: this.selectedEffect } )
      }
    },
  },
}
</script>
