export const GetDashboard = `
  query GetDashboard {
    daily: dashboard(period: DAILY) {
      dateRange {
        from
        to
      }
      compareWith {
        from
        to
      }
      stats {
        type
        value
        evolution
      }
    }
    monthly: dashboard(period: MONTHLY) {
      dateRange {
        from
        to
      }
      compareWith {
        from
        to
      }
      stats {
        type
        value
        evolution
      }
    }
  }
`