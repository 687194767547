<template>
  <Page
    :loading="loading"
    :error="error"
    type="list"
  >
    <template #title>
      Coupons / AutoDiscount
    </template>
    <template #form-modals>
      <AddOrEditModal />
    </template>
    <template #no-reload>
      <v-sheet
        rounded
        elevation="3"
      >
        <v-form>
          <v-container fluid>
            <v-row dense>
              <v-col
                cols="5"
                md="3"
              >
                <v-text-field
                  v-model="filters.code"
                  label="Rechercher"
                  outlined
                  hide-details
                  clearable
                  dense
                />
              </v-col>
              <v-col
                cols="5"
                md="3"
              >
                <v-switch
                  v-model="filters.isAuto"
                  label="auto discounts"
                />
              </v-col>
              <v-col
                cols="2"
                md="6"
                align="right"
              >
                <v-btn
                  fab
                  color="primary"
                  size="small"
                  data-testid="btn-add"
                  @click="openAddModal"
                >
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-sheet>
    </template>
    <template v-if="coupons.length">
      <v-simple-table>
        <thead>
          <tr>
            <th>Code</th>
            <th>Conditions</th>
            <th>Effet</th>
            <th>Nombre d'utilisations</th>
            <th class="text-right">
              Date de création
            </th>
            <th class="text-right">
              Date d'expiration
            </th>
            <th class="text-right">
              -
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="coupon in coupons"
            :key="coupon.id"
            tabIndex="0"
            @keypress.enter="() => onCouponClick(coupon.id)"
          >
            <td>
              <router-link :to="{ name: 'couponDetails', params: { id: coupon.id } }">
                {{ coupon.code }}
              </router-link>
            </td>
            <td class="text-left">
              <v-icon
                tag="span"
                title="Pour un utilisateur"
                class="mr-2"
                color="green darken-3"
                :disabled="coupon.condition.customer === null"
              >
                mdi-account-lock
              </v-icon>
              <v-icon
                tag="span"
                title="Date limite"
                class="mr-2"
                color="green darken-3"
                :disabled="coupon.condition.expiresAt === null"
              >
                mdi-calendar-month-outline
              </v-icon>
              <v-icon
                tag="span"
                title="Nombre de commande exact"
                class="mr-2"
                color="green darken-3"
                :disabled="coupon.condition.numberOfOrders === null"
              >
                mdi-cash-register
              </v-icon>
              <v-icon
                tag="span"
                title="Limité à l'inscription newsletter'"
                class="mr-2"
                color="green darken-3"
                :disabled="coupon.condition.newsletterSubscriptionDays === null"
              >
                mdi-email-newsletter
              </v-icon>
              <v-icon
                tag="span"
                title="Montant minimum requis"
                class="mr-2"
                color="green darken-3"
                :disabled="coupon.condition.minimalAmount === null"
              >
                mdi-cash-lock
              </v-icon>
              <v-icon
                tag="span"
                title="Uniquement sur Appli"
                class="mr-2"
                color="green darken-3"
                :disabled="coupon.condition.appOnly !== true"
              >
                mdi-cellphone-check
              </v-icon>
            </td>
            <td class="text-left">
              {{ coupon.effectDetails }}
            </td>
            <td>
              {{ coupon.usages.totalNumberOfItems }}
            </td>
            <td class="text-right">
              {{ coupon.createdAt }}
            </td>
            <td class="text-right">
              {{ coupon.expiresAt }}
            </td>
            <td class="text-right">
              <v-icon
                v-if="coupon.deactivated"
                color="red darken-3"
              >
                mdi-lock
              </v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <div
        v-if="nbPages"
        class="text-center  mt-8"
      >
        <v-pagination
          v-model="page"
          :length="nbPages"
          :total-visible="8"
          rounded="circle"
        />
      </div>
    </template>
  </Page>
</template>

<script>
import { CouponAction, CouponGetter, CouponMutation } from '@/store/types'
import AddOrEditModal from './AddOrEditModal'
import { mapGetters } from 'vuex'
import Page from '../Layout/Page'

let debounceTimerId

export default {
  components: {
    AddOrEditModal,
    Page,
  },

  data () {
    return {
      filters: this.$store.getters[CouponGetter.Filters],
    }
  },
  computed: {
    ...mapGetters({
      coupons: CouponGetter.List,
      loading: CouponGetter.IsLoading,
      error: CouponGetter.HasError,
      nbPages: CouponGetter.TotalPages,
    }),
    page: {
      get() { return this.$store.getters[CouponGetter.Page] },
      set(value) { this.$store.dispatch(CouponAction.GetAll, value) },
    },
  },
  watch: {
    'filters.code'() {
      clearTimeout(debounceTimerId)
      debounceTimerId = setTimeout(() => {
        this.$store.dispatch(CouponAction.ApplyFilters, this.filters)
      }, 400)
    },
    'filters.isAuto'() {
      clearTimeout(debounceTimerId)
      debounceTimerId = setTimeout(() => {
        this.$store.dispatch(CouponAction.ApplyFilters, this.filters)
      }, 400)
    },
  },
  mounted() {
    this.$store.dispatch(CouponAction.GetAll)
  },
  methods: {
    onCouponClick(id) {
      this.$router.push({ name: 'couponDetails', params: { id } })
    },

    openAddModal() {
      this.$store.commit(CouponMutation.OpenAddModal)
    },
  },

}
</script>
