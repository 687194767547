<template>
  <v-simple-table>
    <template #default>
      <tbody>
        <tr>
          <td style="min-width: 64px;">
            <v-checkbox
              v-if="refundMode && refundableAmountValue > 0"
              v-model="refundSelected"
            />
          </td>
          <td
            class="text-center"
            style="width: 20%;"
          >
            <img
              v-if="image"
              loading="lazy"
              :src="image"
              style="max-width: 100px;"
              alt=""
            >
          </td>
          <td
            class="text-left"
            style="width: 20%;"
          >
            <slot name="name" />
          </td>
          <td
            class="text-center"
            style="width: 20%;"
          >
            {{ extra }}
          </td>
          <td
            class="text-right"
            style="width: 10%;"
          >
            Prix initial <br>
            <span>{{ initialAmount }}</span>
          </td>
          <td
            class="text-right"
            style="width: 10%;"
          >
            <template v-if="reductionAmount">
              Réduction<br>
              <span>{{ reductionAmount }}</span>
            </template>
          </td>
          <td
            class="text-right"
            style="width: 20%;"
          >
            Montant payé<br>
            <span>{{ paidAmount }}</span>
            <template v-if="refundedAmount">
              <div class="font-weight-bold red--text caption">
                {{ refundedAmount }}
              </div>
            </template>
            <v-text-field
              v-if="refundSelected"
              hide-details="auto"
              label="Montant à rembourser"
              step="0.01"
              type="number"
              outlined
              min="0"
              :max="refundableAmountValue"
              required
              style="margin-left: auto; max-width: 180px;"
              :value="amountToRefund"
              @input="onChangeRefundAmount"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    extra: {
      type: String,
      default: '',
    },
    initialAmount: {
      type: String,
      required: true,
    },
    reductionAmount: {
      type: String,
      default: null,
    },
    paidAmount: {
      type: String,
      required: true,
    },
    refundedAmount: {
      type: String,
      default: null,
    },
    refundableAmountValue: {
      type: Number,
      default: null,
    },
    refundMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      refundSelected: false,
      currency: 'EUR',
      amountToRefund: '0',
    }
  },
  watch: {
    refundMode(newValue) {
      if (!newValue) {
        this.refundSelected = false
      }
    },
    refundSelected(newValue) {
      if (!newValue) {
        this.onChangeRefundAmount(0)
      } else {
        this.onChangeRefundAmount(this.refundableAmountValue)
      }
    },
  },
  methods: {
    onChangeRefundAmount(value) {
      this.amountToRefund = Math.min(this.refundableAmountValue, value)
      this.$emit('refundAmountChanged', this.amountToRefund)
    },
  },
}
</script>
